import React from "react"
import { css } from "@emotion/react"
import { mq } from "../utils/style"

const linkStyle = css({
  transition: '.3s ease-in-out',
  display: 'flex',
  [mq('mobile')]: {
    flexDirection: 'column',
  },
  position: 'relative',
  padding: '5px',
  textDecoration: 'none',
  color: '#333',
  cursor: 'pointer',
  boxShadow: '0 1px 5px 0 rgba(0,0,0,.25)',
  '&:hover': {
    boxShadow: '0 5px 15px rgba(0,0,0,.25)',
    color: '#888',
    textDecoration: 'none',
    transform: 'translateY(-2px)',
  },
  marginBottom: '10px',
  '.linkto-title' : {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    textDecoration: 'underline',
    marginBottom: '2px',
  },
  '.linkto-img': {
    [mq('mobile')]: {
      width: '100%',
    },
    img: {
      width: '100%',
    },
    alignSelf: 'center',
    flex: '1 0 120px',
    '&:hover': {
      boxShadow: '0 4px 5px 0 rgba(0,0,0,.25)',
    }
  },
  '.linkto-description': {
    flex: '2 0 160px',
    paddingLeft: '10px',
    [mq('mobile')]: {
      paddingTop: '5px',
    },
  },
})

const urlRegEx = /(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g

export default function SalesTweetList(props) {
  const { tweetList } = props;
  return (
    <>
      {tweetList.map(tweet => {
        return (
          <div css={linkStyle} key={tweet.id}>
          {tweet.media && 
            <div className="linkto-img">
              <img src={tweet.media.url} alt={""}/>
            </div>          
          }
            <div className="linkto-description">
              <div className="linkto-title">{tweet.users && tweet.users.name}</div>
              <div className="linkto-detail"><span dangerouslySetInnerHTML={{__html: tweet.text.replace(urlRegEx, '<a href="$1" target="_blank" rel="nofollow noopenner">$1</a>')}} /></div>
            </div>
        </div>
        )
      })}
    </>
  )
}